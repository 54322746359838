import {Request} from "../../auth/request";
import {toggleSpinner} from "../../util";
import {appAuthUrl} from "../../config";
import i18next from "i18next";
import React, {Component, useRef} from 'react';
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import {Link} from 'react-router-dom';

interface IProps {
	history: any;
	language: string;
}

export default class ForgotPasswordPage extends Component<IProps, any> {
	state: { [key: string]: any } = {
		_forgotPasswordData: {
			email: "",
			app: "clipsynphony",
			redirectUrl: `${window.location.origin}/app/reset-password`,
			recaptchaToken: "",
			language: window.localStorage.i18nextLng,
		},
		_translationLoaded: false,
		_email: "",
		_recaptchaInput: false,
		_disabled: true,
		_forgotMessage: "",
		_forgotStatus: "",
		_language: 'en',
	};
	captchaRef: any;

	// function to set the captcha ref
	setCaptchaRef = (r: any) => {
		this.captchaRef = r;
	};

	render() {

		const {_email, _disabled, _forgotMessage, _forgotStatus} = this.state;

		return (
			<div id="" className={"bg-white content-start min-h-[90vh] dark:bg-gray-800"}>
				<div className="flex flex-col justify-start items-start w-full mt-12 max-w-md mx-auto bg-white dark:bg-gray-800 rounded-lg">
					<div className="flex flex-col justify-start items-center w-full gap-4 px-6 py-8">
						<h1 className="text-4xl font-semibold text-gray-900 dark:text-white">{i18next.t("Forgot Password")}</h1>
						<p className="text-sm text-center text-gray-600 dark:text-gray-400">
							{i18next.t("Enter your email address below and well send you a link to reset your password")}
						</p>
						<div className="w-full space-y-4 mt-4">
							<div className="flex flex-col space-y-2">
								<label className="text-sm flex justify-between font-medium text-gray-900 dark:text-white">
									<span>{i18next.t("Email")}</span>
									<span
										className={`signup-error text-red-500 ${_email ? '' : 'hidden'}`}>{_email ? _email : 'email validation'} </span>
								</label>
								<input onInput={this.forgotPasswordEmailChange} name="_email" type="email"
								       placeholder={i18next.t("Enter your email address")}
								       id="InputEmail"
								       className="py-2 px-3 border border-gray-200 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"/>
							</div>
							<div className="flex justify-start">
								<ReCAPTCHA
									ref={(r: any) => this.setCaptchaRef(r)}
									className="g-recaptcha"
									sitekey="6LcxQjgpAAAAAKe7EBOCgO0KBirP2umkQqBi1B-i"
									onChange={this.captchaChange}
									onExpired={this.captchaExpired}
									onError={this.captchaExpired}
								/>
							</div>
							<div
								className={`signup-message capitalize text-red-500 ${_forgotMessage ? '' : 'hidden'} error signup_${_forgotStatus} mt-2`}>
								{_forgotMessage ? i18next.t(_forgotMessage.toString().replace("!", "")) : 'response validation'}
							</div>
							<div className="w-full mt-4">
								<button onClick={!_disabled ? this.forgotPasswordClick : this.validateSignUp}
								        className="py-2 w-full text-center block px-4 bg-[#27aae1] text-white rounded-md">{i18next.t("Reset Password")}</button>
							</div>
							<div className="text-center text-black dark:!text-white text-sm mt-4">
								{i18next.t("Get Back to")} <Link to="/" className="text-clip-blue text-sm hover:underline">{i18next.t("Login")}</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	forgotPasswordEmailChange = async (e: any) => {
		const name = e.target.name;
		const value = e.target.value;
		const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

		if (name === "_email" && (!value || !re.test(value))) {
			this.setState({[name]: i18next.t("Please enter a valid email")});
			this.validateSignUp();
			return;
		}

		this.setState({
			[name]: "",
			_forgotPasswordData: {...this.state._forgotPasswordData, [name.toString().replace("_", "")]: value}
		});
		this.validateSignUp();
	};

	validateSignUp = async () => {
		if (this.state._forgotPasswordData.email && this.state._recaptchaInput) {
			this.setState({_disabled: false, _forgotMessage: "", _forgotStatus: ""});
		} else {
			// if the forgot password email is empty
			// then let's display that message
			this.setState({
				_disabled: true,
				_forgotMessage: i18next.t("input all fields"),
				_forgotStatus: "red"
			});
		}
	};

	forgotPasswordClick = async (e: any) => {
		e.preventDefault();
		try {
			toggleSpinner();
			const res = await Request.post(`${appAuthUrl}/password-reset/request`, this.state._forgotPasswordData);
			this.captchaRef.reset();
			toggleSpinner();

			if (res && res.status === 200) {
				this.props.history.push("/email-sent");
			} else {
				this.setState({_forgotMessage: res.message, _forgotStatus: "red"});
			}

		} catch (e) {
			toggleSpinner();
			this.setState({_forgotMessage: i18next.t("Password change request failed!")})
		}
	};

	captchaChange = async (res: any) => {
		await this.setState({_forgotPasswordData: {...this.state._forgotPasswordData, recaptchaToken: res}});
		await this.setState({_recaptchaInput: true});
		this.validateSignUp();
		return false;
	};

	captchaExpired = async (e: any) => {
		await this.setState({_forgotPasswordData: {...this.state._forgotPasswordData, recaptchaToken: ""}});
		await this.setState({_recaptchaInput: false});
		this.validateSignUp();
		return false;
	};
}
