import { Component } from 'react';
import i18next from "i18next";
import { Request } from "../../auth/request";
import { toggleSpinner } from "../../util";
import { appAuthUrl } from "../../config";
import { Link } from 'react-router-dom';


interface IProps {
  history: any;
  language: string;
  isDarkMode: boolean;
}
export default class LoginPage extends Component<IProps, any> {

  state: {[key:string]: any} = {
    _loginData: { email: "", password: "", app: "clipsynphony" },
    _loginState: false,
    _rememberMe: false,
    _loginMessage: "",
    _disabled: true,
    _language: 'en',
    password_type: "password",
  };

  render() {
    const {_disabled, _loginMessage, _rememberMe} = this.state;
    let icon = this.props.isDarkMode ? "white" : "black";
    if(this.state.password_type !== "password") {
      icon = this.props.isDarkMode ? "off-white" : "off";
    }

    return (
      <div className={"bg-white content-center min-h-[91vh] dark:bg-gray-800"}>
        <div className="flex flex-col justify-start items-start w-full max-w-md mx-auto rounded-lg p-6">
          <div className="flex flex-col w-full justify-start items-center gap-4">
            <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">{i18next.t("Login")}</h1>
            <p className="text-sm text-center text-gray-500 dark:text-gray-300">
              {i18next.t("Welcome back Login to your account")}.
            </p>
          </div>
          <div className="flex flex-col w-full gap-6 mt-4">
            <button onClick={this.googleLogin}
                    className="flex items-center justify-center gap-2 py-2 px-4 bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-md">
              <img
                src="https://storage.googleapis.com/clipsymphony_static_assets/google-logo-png-google-icon-logo-png-transparent-svg-vector-bie-supply-14.png"
                alt="Google" className="w-4 h-4"/>
              <span
                className="text-sm font-medium text-gray-900 dark:text-white">{i18next.t("Login with Google")}</span>
            </button>
            <button onClick={this.facebookLogin}
                    className="flex items-center justify-center gap-2 py-2 px-4 bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-md">
              <img src="https://storage.googleapis.com/clipsymphony_static_assets/facebook.png" alt="Facebook"
                   className="w-4 h-4"/>
              <span
                className="text-sm font-medium text-gray-900 dark:text-white">{i18next.t("Login with Facebook")}</span>
            </button>
            <div className="flex items-center w-full gap-2">
              <div className="flex-grow h-px bg-gray-200 dark:bg-gray-600"></div>
              <span className="text-xs text-gray-500 dark:text-gray-300">{i18next.t("OR CONTINUE WITH EMAIL")}</span>
              <div className="flex-grow h-px bg-gray-200 dark:bg-gray-600"></div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col space-y-2">
                <label className="text-sm font-medium text-gray-900 dark:text-white">{i18next.t("Email")}</label>
                <input onInput={this.emailChange} type="email" placeholder={i18next.t("Enter your email address")}
                       className="py-2 px-3 border border-gray-200 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"/>
              </div>
              <div className="flex flex-col space-y-2">
                <label className="text-sm font-medium flex justify-between text-gray-900 dark:text-white">
                  <span>{i18next.t("Password")}</span>
                  <span className="underline"><Link to="/forgot-password">{i18next.t("Forgot Password")}</Link></span>
                </label>
                <div className="relative">
                  <input onInput={this.passwordChange} type={this.state.password_type}
                         placeholder={i18next.t("Enter your password")}
                         className="w-full py-2 px-3 border border-gray-200 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"/>
                  <img onClick={(e) => {
                    e.preventDefault();
                    this.setState({password_type: this.state.password_type === "password" ? "text" : "password"})
                  }} className="absolute h-5 w-5 right-3 top-3 cursor-pointer"
                       src={`https://storage.googleapis.com/clipsymphony_static_assets/Icons/visibile%20-%20${icon}.svg`}
                       alt="Toggle password visibility"/>
                </div>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <input
                value={_rememberMe}
                onChange={this.rememberMeClick}
                id="remember_me"
                type="checkbox"
                className="form-checkbox cursor-pointer rounded h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
              />
              <label
                htmlFor="remember_me"
                className="text-sm cursor-pointer font-medium text-gray-900 dark:text-white"
              >
                {i18next.t('Remember me')}
              </label>
            </div>
            {
              _loginMessage && (
                <div className="text-left text-red-500">{i18next.t(_loginMessage)}</div>
              )
            }
            <button onClick={!_disabled ? this.loginClick : this.validateLogin}
                    className="py-2 px-4 bg-[#27aae1] text-white rounded-md">{i18next.t("Login")}</button>
          </div>
          <p className="mt-4 w-full text-sm text-center text-gray-900 dark:text-white">
            {i18next.t("Dont have an account yet")} <Link to="/signup" className="text-blue-500">{i18next.t("Signup")}</Link>
          </p>
        </div>
      </div>
    )
  }

  validateLogin = () => {
    if (this.state._loginData.email && this.state._loginData.password) {
      this.setState({_disabled: false, _loginMessage: ""});
    } else {
      this.setState({_disabled: true, _loginMessage: i18next.t("Input Email And Password")});
    }
  };

  emailChange = async (e: any) => {
    await this.setState({_loginData: {...this.state._loginData, email: e.target.value}});
    this.validateLogin();
  };

  passwordChange = async (e: any) => {
    await this.setState({_loginData: {...this.state._loginData, password: e.target.value}});
    this.validateLogin();
  };

  rememberMeClick = () => {
    this.setState({_rememberMe: !this.state._rememberMe});
    return false;
  };

  loginClick = async (e: any) => {
    e.preventDefault();

    try {
      toggleSpinner();
      const res = await Request.post(`${appAuthUrl}/login${window.location.search}`, this.state._loginData);
      toggleSpinner();

      // the login was successful
      if (res.token) {

        if (this.state._rememberMe) {
          localStorage.setItem("token", res.token);
          localStorage.setItem("refresh", res.refresh);
        }

        this.setState({_loginMessage: ""});
        // await store.dispatch(updateToken({ token: res.token, expires: res.expires }));
        // if res already has a last logged in team, then re-route to articles with that team
        // or the user has no teams
        if (res.scope || !res.teams || (res.teams && !res.teams.length)) {
          return window.location.replace(`/app/login?token=${res.token}&expires=${res.expires}&scope=${res.scope}`)
        }
        // the user has no last logged in team
        // and has only one team
        // then use that team as default and route to the articles page
        if (res.teams && res.teams.length === 1) {
          return window.location.replace(`/app/login?token=${res.token}&expires=${res.expires}&scope=${res.teams[0].id}`)
        }

      } else {
        this.setState({_loginMessage: res.message});
      }
    } catch (e) {
      toggleSpinner();
    }
  };

  componentWillMount(): void {
    toggleSpinner();
    const token = localStorage.getItem("token");
    if (token) {
      return window.location.replace(`/app/login?token=${token}`)
    } else {
      toggleSpinner();
    }
  }

  googleLogin = (e: any) => {
    e.preventDefault();
    localStorage.setItem("loginItem", "google");
    window.location.href = `${appAuthUrl}/auth/google?provider=google`
  };

  facebookLogin = (e: any) => {
    e.preventDefault();
    localStorage.setItem("loginItem", "facebook");
    window.location.href = `${appAuthUrl}/auth/facebook?provider=facebook`
  };
}
