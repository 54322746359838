
//@ts-ignore
const appServerUrl = window.appServerUrl;
//@ts-ignore
const appRedirectUrl = window.appRedirectUrl;
//@ts-ignore
const languages = window.languages;
//@ts-ignore
const appAuthUrl = window.appAuthUrl;
//@ts-ignore
const appUserUrl = window.appUserUrl;
//@ts-ignore
const appComplaintUrl = window.appComplaintUrl;
//@ts-ignore
const env = window.env;

export { appServerUrl, appAuthUrl, env, appRedirectUrl, languages, appUserUrl, appComplaintUrl };

