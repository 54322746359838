import { appServerUrl } from "../config";


export class Request {

  static base(url: string, method: string, data: object) {
    return fetch(`${appServerUrl}/${url}`, {
        method: method,
        mode: "no-cors", // no-cors, cors, *same-origin
        // cache: config.env === "dev" ? "no-cache" : "force-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "include", // include, *same-origin, omit
        redirect: "follow",
        headers: { "Content-Type": "application/json", "Accept": "application/json" },
        body: JSON.stringify(data),
      })
      .then(response => response.json());
  }

  static post(url: string, data: any) {
    return fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include", // include, *same-origin, omit
      redirect: "follow",
      headers: { "Content-Type": "application/json"}
    }).then(res => res.json());
  }
  
  static put(url: string, data: any) {
    return fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include", // include, *same-origin, omit
      redirect: "follow",
      headers: { "Content-Type": "application/json"}
    }).then(res => res.json());
  }

  static postWithAuth(url: string, data: any, token: string) {
    return fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include", // include, *same-origin, omit
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token || ""
      },
    }).then(res => res.json());
  }

  static getWithAuth(url: string, token: string) {
    return fetch(url, {
      method: "GET",
      credentials: "include", // include, *same-origin, omit
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token || ""
      },
    }).then(res => res.json());
  }

  static delete(url: string, data: object) {
    return this.base(url, "DELETE", data);
  }

  static get(url: string) {
    return fetch(url).then(response => response.json());
  }

}
