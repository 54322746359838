type Plan = {
    name: string
    description: string
    charge?: number
}


class Plans {
    readonly Basic: Plan = {
        name: 'Basic',
        description: 'Basic individual plan',
    }

    readonly Professional: Plan = {
        name: 'Professional',
        description: 'Professional plan',
    }

    readonly Enterprise: Plan  = {
        name: 'Enterprise',
        description: 'Enterprise plan',
    }

    getBasicPlanName(): string {
        return this.Basic.name
    }

    getProfessionalPlanName(): string {
        return this.Professional.name
    }

    getEnterprisePlanName(): string {
        return this.Enterprise.name
    }
}

const SubscriptionPlan =  new Plans()

export default SubscriptionPlan