import { Route, Switch, } from "react-router-dom";
import { Component } from 'react';
import i18next from 'i18next';

import translationInit from "../../language";
import "./app-index.scss";
import LoginPage from '../login-page/login-page';
import { connect } from 'react-redux';
import SignupPage from '../signup-page/signup-page';
import AppTermsAndConditions from '../terms-and-conditions/terms-and-conditions';
import ForgotPasswordPage from '../forgot-password/forgot-password';
import Header from '../header/header';
import AppNewLinkSent from '../new-link-sent/new-link-sent';
import AppConfirmedEmail from '../confirmed-email/confirmed-email';
import AppConfirmationProblem from '../confirmation-problem/confirmation-problem';
import AppPrivacyPolicy from '../privacy-policy/privacy-policy';
import AppAlreadyConfirmed from '../already-confirmed/already-confirmed';
import AppLinkExpired from '../link-expired/link-expired';
import EmailSentPage from '../email-sent/email-sent';
import ConfirmSignupLoginPage from '../confirm-signup/confirm-signup';
import SelectTeamPage from '../select-team/select-team';
import PasswordResetPage from '../password-reset/password-reset';
import { AppState } from '../../reducers/app';
import AppVerifySocialSignUp from '../verify-social-signup/verify-social-signup';
import Cookie from '../cookie-acceptance/cookie';
import { ContactForm } from '../contact-complaint/contact-form';
import { ComplaintForm } from '../contact-complaint/complaint-form';
import OnBoardingProgressionForm from "../onboarding";

interface IProps {
  language: string;
  history: any;
}

export class AppIndex extends Component<IProps, any> {

  state = {
    language: this.props.language,
    isDarkMode: window.matchMedia('(prefers-color-scheme: dark)').matches || localStorage.getItem('darkMode') === "true",
  };

  toggleDarkMode = () => {
    this.setState({isDarkMode: !this.state.isDarkMode}, () => {
      const newMode = this.state.isDarkMode;
      if (newMode) {
        document.documentElement.classList.add('dark');
        window.localStorage.setItem("darkMode", "true")
      } else {
        document.documentElement.classList.remove('dark');
        window.localStorage.removeItem("darkMode")
      }
    });
  };

  handleChange = (e:any) => {
    this.setState({isDarkMode: e.matches});
  };

  componentDidMount() {
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)").matches || window.localStorage.getItem("darkMode") === "true";
    if (prefersDarkScheme) {
      this.setState({isDarkMode: true});
      document.documentElement.classList.add('dark');
      window.localStorage.setItem("darkMode", "true")
    }
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    // Listen for changes to the system preference
    mediaQuery.addEventListener('change', this.handleChange);
  }

  render() {
    const { language, isDarkMode } = this.state;

    return (
      <main className="main bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100">
        <Header toggleDarkMode={this.toggleDarkMode} isDarkMode={isDarkMode} changeLanguage={this.changeLanguage} language={language}/>
        <Switch>
          <Route path="/">
            <Switch>
              <Route path="/signup"> <SignupPage isDarkMode={isDarkMode} language={language} history={this.props.history}/> </Route>
              <Route path="/login"> <LoginPage isDarkMode={isDarkMode} language={language} history={this.props.history}/> </Route>
              <Route path="/forgot-password"> <ForgotPasswordPage language={language} history={this.props.history}/> </Route>
              <Route path="/email-sent"> <EmailSentPage language={language} history={this.props.history}/> </Route>
              <Route path="/confirm"> <ConfirmSignupLoginPage isDarkMode={isDarkMode} language={language} history={this.props.history}/> </Route>
              <Route path="/select-team"> <SelectTeamPage language={language} history={this.props.history}/> </Route>
              <Route path="/reset-password"> <PasswordResetPage isDarkMode={isDarkMode} language={language} history={this.props.history}/> </Route>
              <Route path="/terms-and-conditions"> <AppTermsAndConditions language={language} history={this.props.history}/> </Route>
              <Route path="/link-expired"> <AppLinkExpired language={language} history={this.props.history}/> </Route>
              <Route path="/already-confirmed"> <AppAlreadyConfirmed language={language} history={this.props.history}/> </Route>
              <Route path="/privacy-policy"> <AppPrivacyPolicy language={language} history={this.props.history}/> </Route>
              <Route path="/link-sent"> <AppNewLinkSent language={language} history={this.props.history}/> </Route>
              <Route path="/successful-confirmation"> <AppConfirmedEmail language={language} history={this.props.history}/> </Route>
              <Route path="/confirmation-problem"> <AppConfirmationProblem language={language} history={this.props.history}/> </Route>
              <Route path="/verify/social-media"> <AppVerifySocialSignUp language={language} history={this.props.history}/> </Route>
              <Route path="/contact-form/:tempId"> <ContactForm language={language} history={this.props.history}/> </Route>
              <Route path="/complaint-form/:tempId"> <ComplaintForm language={language} history={this.props.history}/> </Route>
              <Route path="/onboarding"> <OnBoardingProgressionForm language={language} history={this.props.history} /> </Route>
              <Route path="/"> <LoginPage isDarkMode={isDarkMode} language={language} history={this.props.history}/> </Route>
              <Route path="*"> <LoginPage isDarkMode={isDarkMode} language={language} history={this.props.history}/> </Route>
            </Switch>
          </Route>
        </Switch>
        <Cookie language={language} />
      </main>
    )
  }

  changeLanguage = (lang: string) => {
    return async () => {
      await i18next.changeLanguage(lang).catch(r => {console.log(r)});
      await this.setState({...this.state, language: lang});
      window.localStorage.i18nextLng = lang;
    }
  };

  componentWillMount(): void {
    translationInit().then(() => {
      console.log('Translation successfully Initialized...');
      i18next.changeLanguage(this.props.language).catch(r => {console.log(r)});
      this.setState({...this.state, language: this.props.language});
      window.localStorage.i18nextLng = this.props.language;
    });
  }
}

const mapDispatchToProps = {};
const mapStateToProps = (state: AppState, ownProps: any) => {
  return {language: window.localStorage.i18nextLng || navigator.language.split("-")[0]}
};
export default connect(mapStateToProps, mapDispatchToProps)(AppIndex);
