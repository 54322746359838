import React, {Component} from 'react';
import "./verify-social-signup.scss";
import i18next from 'i18next';
import {Link} from 'react-router-dom';
import {toggleSpinner} from '../../util';
import {Request} from '../../auth/request';
import {appAuthUrl} from '../../config';
import SubscriptionPlan from "../../constants/plan";


interface IProps {
	history: any;
	language: string;
}

export default class AppVerifySocialSignUp extends Component<IProps, any> {

	state: any = {
		verificationId: "",
		verifyMessage: "",
		verifyError: false,
		email: "",
		username: "",
		plan: "Basic",
		trackUser: false,
	};

	render() {
		return (
			<div
				className="privacy-terms pt-4 flex justify-center items-center min-h-screen"
			>
				<div
					className="verify-social bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 p-10 max-w-lg w-full"
					style={{borderRadius: "10px"}}
				>
					<h1
						className="text-center mb-12 text-2xl font-bold text-gray-900 dark:text-gray-100"
					>
						{i18next.t("Create Your Account")}
					</h1>
					<div className="login-consumer-type mb-20"></div>
					<div className="px-8">
						<section className={"py-4"}>
							<p className="text-gray-700 dark:text-gray-300">
								{i18next.t("Email")}
							</p>
							<b className="text-gray-900 dark:text-gray-100">{this.state.email}</b>
						</section>
						<section className={"py-4"}>
							<p className="text-gray-700 dark:text-gray-300">
								{i18next.t("Username")}
							</p>
							<b className="text-gray-900 dark:text-gray-100">
								{this.state.username || "N/A"}
							</b>
						</section>
					</div>
					<br/>
					<div className="login-helper verify text-center mt-20">
						<label className="checkbox hidden">
							<input
								onChange={this.user_tracking_consent_change}
								id="remember_me"
								type="checkbox"
								name="remember"
								defaultChecked={false}
								checked={this.state.trackUser}
							/>
							<span className="control-indicator tracking">
          &nbsp;
								{i18next.t(
									"I agree to allow ClipSymphony to collect and store my preferences, behavior, and device information to enhance my overall experience"
								)}
        </span>
						</label>
						<br/>
						<label className="text-center">
						</label>
					</div>
					<p className="mt-4 mb-8 text-sm w-full text-center text-gray-500 dark:text-gray-300">
						{i18next.t("By signing up you agree to our")} <Link className={"cursor-pointer text-clip-blue"}
						                                                    to={"/terms-and-conditions"}> {i18next.t("Terms of Service")} </Link> {i18next.t("and")} <Link className={"cursor-pointer text-clip-blue"}
						      to={"/privacy-policy"}>{i18next.t("Privacy Policy")}</Link>
					</p>
					<div className="login-buttons mb-4 text-center">
						<div className={"mb-4"}>
							{this.state.verifyMessage && (
								<div
									className={`verify signup-message signup_error_${this.state.verifyError} mt-2 mb-2`}
								>
									{this.state.verifyMessage}
									<br/>
								</div>
							)}
							<input
								onClick={this.verifySocialMediaAccount}
								name="submit"
								className="login cursor-pointer bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
								type="submit"
								value={i18next.t("Create Account").toString()}
							/>
						</div>
						<p className="mt-2 w-full text-sm text-center text-gray-900 dark:text-white">
							{i18next.t("Already have an account")} <Link to="/login"
							                                             className="text-clip-blue">{i18next.t("Login")}</Link>
						</p>
					</div>
				</div>
			</div>
		);
	}

	user_tracking_consent_change = async (e: any) => {
		await this.setState({trackUser: !this.state.trackUser});
		return false;
	};

	verifySocialMediaAccount = async (e: any) => {
		e.preventDefault();

		try {
			toggleSpinner();
			const res = await Request.get(`${appAuthUrl}/signup/verify/${this.state.verificationId}?plan=${this.state.plan}&trackUser=${this.state.trackUser}`);
			toggleSpinner();

			if (res.redirectUrl) {
				this.setState({verifyMessage: res.message, verifyError: res.error});
				// redirect the user after login to confirmation page.
				window.location.href = res.redirectUrl;
			} else {
				this.setState({verifyMessage: res.message, verifyError: res.error});
			}
		} catch (e) {
			toggleSpinner();
			this.setState({verifyMessage: "Error making request", verifyError: true});
		}
	};

	componentWillMount() {
		const params = new URLSearchParams(window.location.search);
		if (!params.get("verificationId")) return this.props.history.push("/");
		this.setState({
			verificationId: params.get("verificationId"),
			email: params.get("email"),
			username: params.get("username")
		});
	}
}
