import React, { Component } from 'react';
import "./confirmed-email.scss";
import { Link } from 'react-router-dom';
import i18next from 'i18next';


interface IProps {
  history: any;
  language: string;
}

export default class AppConfirmedEmail extends Component<IProps, any> {
  render() {
    return (
      <div className="flex items-start justify-center min-h-[90vh] bg-gray-100 dark:bg-gray-800">
        <div
          className="max-w-md w-full dark:bg-gray-800 mt-8 p-6 rounded-lg text-gray-800 dark:text-gray-100">
          <div className="sub-text text-center">
            <h2 className="title text-xl font-semibold mb-4">{i18next.t("Your email address has been verified")}</h2>
            <p className="mb-6">
              {i18next.t("Thank you and welcome on board. Now you can start using Clipsymphony")}
            </p>
            <div className="send-button">
              <Link to="/" className="text-clip-blue hover:underline">
                {i18next.t("Go to Login Page")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
