import { Component } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  history: any;
  language: string;
}

export default class AppTermsAndConditions extends Component<IProps, any> {
  render() {
    return (
      <>
        <div className="privacy-terms p-4 md:p-12 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100">
          <div className="sub-text mb-6">
            <h2 className="title text-2xl font-bold text-clip-blue">Terms of Use</h2>
            <p className="text-sm text-gray-600 dark:text-gray-400">Last updated: January 13, 2023</p>
          </div>

          <div className="sub-text agreement-to-terms mb-6">
            <h2 className="text-xl font-semibold text-clip-blue">Agreement to Terms</h2>
            <div className="sub-text mt-4 space-y-4">
              <p className="leading-relaxed">
                These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and ClipSymphony, Inc., 1111B South Governors Avenue, Suite 21681 Dover, DE 19904 US ("Company," “we,” “us,” or “our’), concerning your access to and use of the https://www.clipsymphony.com website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”).
                You agree that by accessing the Site, you have read, understood, and agreed to be bound by all of these Terms of Use.
                IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
              </p>
              <p className="leading-relaxed">
                Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference.
                We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of these Terms of Use, and you waive any right to receive specific notice of each such change.
                Please ensure that you check the applicable Terms every time you use our Site so that you understand which Terms apply.
                You will be subject to and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms of Use by your continued use of the Site after the date such revised Terms of Use are posted.
              </p>
              <p className="leading-relaxed">
                The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.
                Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
              </p>
            </div>
          </div>

          <div className="sub-text intellectual-property mb-6">
            <h2 className="text-xl font-semibold text-clip-blue">Intellectual Property Rights</h2>
            <div className="sub-text mt-4 space-y-4">
              <p className="leading-relaxed">
                Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States, international copyright laws, and international conventions.
                The Content and the Marks are provided on the Site “AS IS” for your information and personal use only.
                Except as expressly provided in these Terms of Use, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
              </p>
              <p className="leading-relaxed">
                Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use.
                We reserve all rights not expressly granted to you in and to the Site, the Content, and the Marks.
              </p>
            </div>
          </div>

          <div className="sub-text user-representation mb-6">
            <h2 className="text-xl font-semibold text-clip-blue">User Representation</h2>
            <div className="sub-text mt-4 space-y-4">
              <p className="leading-relaxed">
                By using the Site, you represent and warrant that: <br/>
                (1) you have the legal capacity and you agree to comply with these Terms of Use; <br/>
                (2) you are not a minor in the jurisdiction in which you reside; <br/>
                (3) you will not access the Site through automated or non-human means, whether through a bot, script, or otherwise; <br/>
                (4) you will not use the Site for any illegal or unauthorized purpose; and <br/>
                (5) your use of the Site will not violate any applicable law or regulation. <br/>
              </p>
              <p className="leading-relaxed">
                If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof).
              </p>
            </div>
          </div>

          <div className="sub-text user-generated-contributions mb-6">
            <h2 className="text-xl font-semibold text-clip-blue">User-Generated Contributions</h2>
            <div className="sub-text mt-4 space-y-4">
              <p className="leading-relaxed">
                The Site provides you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Site, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "Contributions").
                Contributions may be viewable by other users of the Site and through third-party websites.
                As such, any Contributions you transmit may be treated in accordance with the Site Privacy Policy.
                When you create or make available any Contributions, you thereby represent and warrant that:
              </p>
              <ul className="list-disc list-inside space-y-2">
                <li>The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.</li>
                <li>You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the Site, and other users of the Site to use your Contributions in any manner contemplated by the Site and these Terms of Use.</li>
                <li>You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Site and these Terms of Use.</li>
                <li>Your Contributions are not false, inaccurate, or misleading.</li>
                <li>Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.</li>
                <li>Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).</li>
                <li>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</li>
                <li>Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people.</li>
                <li>Your Contributions do not violate any applicable law, regulation, or rule.</li>
                <li>Your Contributions do not violate the privacy or publicity rights of any third party.</li>
                <li>Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.</li>
                <li>Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.</li>
                <li>Your Contributions do not otherwise violate or link to material that violates, any provision of these Terms of Use, or any applicable law or regulation.</li>
              </ul>
              <p className="leading-relaxed">
                Any use of the Site in violation of the foregoing violates these Terms of Use and may result in, among other things, termination or suspension of your rights to use the Site.
              </p>
            </div>
          </div>

          <div className="sub-text contribution-license mb-6">
            <h2 className="text-xl font-semibold text-clip-blue">Contribution License</h2>
            <div className="sub-text mt-4 space-y-4">
              <p className="leading-relaxed">
                You agree that we may access, store, process, and use any information and personal data that you provide following the terms of the Privacy Policy and your choices (including settings).
              </p>
              <p className="leading-relaxed">
                By submitting suggestions or other feedback regarding the Site, you agree that we can use and share such feedback for any purpose without compensation to you.
              </p>
              <p className="leading-relaxed">
                We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions.
                We are not liable for any statements or representations in your Contributions provided by you in any area on the Site.
                You are solely responsible for your Contributions to the Site and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.
              </p>
            </div>
          </div>

          <div className="sub-text submissions mb-6">
            <h2 className="text-xl font-semibold text-clip-blue">Submissions</h2>
            <div className="sub-text mt-4 space-y-4">
              <p className="leading-relaxed">
                You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the Site ("Submissions") provided by you to us are non-confidential and shall become our sole property.
                We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.
                You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such Submissions are original with you or that you have the right to submit such Submissions.
                You agree there shall be no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions.
              </p>
            </div>
          </div>

          <div className="sub-text site-management mb-6">
            <h2 className="text-xl font-semibold text-clip-blue">Site Management</h2>
            <div className="sub-text mt-4 space-y-4">
              <p className="leading-relaxed">
                We reserve the right, but not the obligation, to <br/>
                (1) monitor the Site for violations of these Terms of Use; <br/>
                (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including without limitation, reporting such user to law enforcement authorities; <br/>
                (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; <br/>
                (4) in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and <br/>
                (5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site. <br/>
              </p>
            </div>
          </div>

          <div className="sub-text term-and-termination mb-6">
            <h2 className="text-xl font-semibold text-clip-blue">Term and Termination</h2>
            <div className="sub-text mt-4 space-y-4">
              <p className="leading-relaxed">
                These Terms of Use shall remain in full force and effect while you use the Site.
                WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
              </p>
              <p className="leading-relaxed">
                If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
              </p>
            </div>
          </div>

          <div className="sub-text modification-interruptions mb-6">
            <h2 className="text-xl font-semibold text-clip-blue">Modifications and Interruptions</h2>
            <div className="sub-text mt-4 space-y-4">
              <p className="leading-relaxed">
                We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the Site without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site.
              </p>
              <p className="leading-relaxed">
                We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the Site. Nothing in these Terms of Use will be construed to obligate us to maintain and support the Site or to supply any corrections, updates, or releases in connection therewith.
              </p>
            </div>
          </div>

          <div className="sub-text governing-law mb-6">
            <h2 className="text-xl font-semibold text-clip-blue">Governing Law</h2>
            <div className="sub-text mt-4 space-y-4">
              <p className="leading-relaxed">
                These conditions are governed by and interpreted following the laws of Germany, and the use of the United Nations Convention of Contracts for the International Sale of Goods is expressly excluded. If your habitual residence is in the EU, and you are a consumer, you additionally possess the protection provided to you by obligatory provisions of the law of your country of residence. ClipSymphony, Inc. and yourself both agree to submit to the non-exclusive jurisdiction of the courts of Dusseldorf, which means that you may make a claim to defend your consumer protection rights in regards to these Terms of Use in Germany, or in the EU country in which you reside.
              </p>
            </div>
          </div>

          <div className="sub-text governing-accounts mb-6">
            <h2 className="text-xl font-semibold text-clip-blue">Dispute Resolution</h2>
            <div className="sub-text mt-4 space-y-4">
              <h3 className="text-lg font-medium text-clip-blue">Informal Negotiations</h3>
              <p className="leading-relaxed">
                To expedite resolution and control the cost of any dispute, controversy, or claim related to these Terms of Use (each “Dispute” and collectively, the “Disputes”) brought by either you or us (individually, a “Party” and collectively, the “Parties”), the Parties agree to first attempt to negotiate any Dispute (except those Disputes expressly provided below) informally for at least thirty (30) days before initiating arbitration. Such informal negotiations commence upon written notice from one Party to the other Party.
              </p>
            </div>

            <div className="sub-text mt-4 space-y-4">
              <h3 className="text-lg font-medium text-clip-blue">Binding Arbitration</h3>
              <p className="leading-relaxed">
                Any dispute arising from the relationships between the Parties to this contract shall be determined by one arbitrator who will be chosen in accordance with the Arbitration and Internal Rules of the European Court of Arbitration being part of the European Centre of Arbitration having its seat in Strasbourg, and which are in force at the time the application for arbitration is filed, and of which adoption of this clause constitutes acceptance. The seat of arbitration shall be Dusseldorf, Germany. The language of the proceedings shall be German. Applicable rules of substantive law shall be the law of Germany.
              </p>
            </div>

            <div className="sub-text mt-4 space-y-4">
              <h3 className="text-lg font-medium text-clip-blue">Restrictions</h3>
              <p className="leading-relaxed">
                The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class-action procedures; and (c) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the general public or any other persons.
              </p>
            </div>

            <div className="sub-text mt-4 space-y-4">
              <h3 className="text-lg font-medium text-clip-blue">Exceptions to Arbitration</h3>
              <p className="leading-relaxed">
                The Parties agree that the following Disputes are not subject to the above provisions concerning informal negotiations and binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity of, any of the intellectual property rights of a Party; (b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive relief. If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.
              </p>
            </div>
          </div>

          <div className="sub-text corrections mb-6">
            <h2 className="text-xl font-semibold text-clip-blue">Corrections</h2>
            <div className="sub-text mt-4 space-y-4">
              <p className="leading-relaxed">
                There may be information on the Site that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice.
              </p>
            </div>
          </div>

          <div className="sub-text disclaimer mb-6">
            <h2 className="text-xl font-semibold text-clip-blue">Disclaimer</h2>
            <div className="sub-text mt-4 space-y-4">
              <p className="leading-relaxed">
                THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY: <br/>
                (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,<br/>
                (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE,<br/>
                (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN,<br/>
                (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE,<br/>
                (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR<br/>
                (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. <br/>
                (7) WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. <br/>
                (8) AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE. <br/>
              </p>
            </div>
          </div>

          <div className="sub-text limitations-of-liability mb-6">
            <h2 className="text-xl font-semibold text-clip-blue">Limitations of Liability</h2>
            <div className="sub-text mt-4 space-y-4">
              <p className="leading-relaxed">
                IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
              </p>
            </div>
          </div>

          <div className="sub-text indemnification mb-6">
            <h2 className="text-xl font-semibold text-clip-blue">Indemnification</h2>
            <div className="sub-text mt-4 space-y-4">
              <p className="leading-relaxed">
                You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of:<br/>
                (1) use of the Site; <br/>
                (2) breach of these Terms of Use; <br/>
                (3) any breach of your representations and warranties set forth in these Terms of Use; <br/>
                (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or <br/>
                (5) any overt harmful act toward any other user of the Site with whom you connected via the Site. <br/>
                Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims.
                We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
              </p>
            </div>
          </div>

          <div className="sub-text user-data mb-6">
            <h2 className="text-xl font-semibold text-clip-blue">User Data</h2>
            <div className="sub-text mt-4 space-y-4">
              <p className="leading-relaxed">
                We will maintain certain data that you transmit to the Site for the purpose of managing the performance of the Site, as well as data relating to your use of the Site.
                Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Site.
                You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.
              </p>
            </div>
          </div>

          <div className="sub-text electronic-communications-transactions-and-signatures mb-6">
            <h2 className="text-xl font-semibold text-clip-blue">Electronic Communications, Transactions, and Signatures</h2>
            <div className="sub-text mt-4 space-y-4">
              <p className="leading-relaxed">
                Visiting the Site, sending us emails, and completing online forms constitute electronic communications.
                You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email, and on the Site, satisfy any legal requirement that such communication is in writing.
                YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO THE ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE.
                You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.
              </p>
            </div>
          </div>

          <div className="sub-text miscellaneous mb-6">
            <h2 className="text-xl font-semibold text-clip-blue">Miscellaneous</h2>
            <div className="sub-text mt-4 space-y-4">
              <p className="leading-relaxed">
                These Terms of Use and any policies or operating rules posted by us on the Site or in respect to the Site constitute the entire agreement and understanding between you and us.
                Our failure to exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of such right or provision.
                These Terms of Use operate to the fullest extent permissible by law.
                We may assign any or all of our rights and obligations to others at any time.
                We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control.
                If any provision or part of a provision of these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of any remaining provisions.
                There is no joint venture, partnership, employment, or agency relationship created between you and us as a result of these Terms of Use or use of the Site.
                You agree that these Terms of Use will not be construed against us by virtue of having drafted them.
                You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.
              </p>
            </div>
          </div>

          <div className="sub-text contact-us mb-6">
            <h3 className="mid text-lg font-medium text-clip-blue">Contact Us</h3>
            <p className="leading-relaxed">
              If you have any questions about this Privacy Policy, You can contact us: <br/>
              <ul className="list-disc list-inside space-y-2">
                <li>By email: info@clipsymphony.com</li>
                <li>By visiting this page on our website: <a href="https://www.clipsymphony.com/" target="_blank" rel="noreferrer" className="text-clip-blue hover:underline cursor-pointer">https://www.clipsymphony.com/impressum.html</a></li>
                <li>By phone number: 0211 / 987407-0</li>
              </ul>
            </p>
          </div>

          <div className="send-button text-center mt-8">
            <Link to={"/signup"} className="text-clip-blue hover:underline cursor-pointer">
              Back to Sign up page
            </Link>
          </div>
        </div>
      </>
    );
  }
}
