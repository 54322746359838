import { Component } from 'react';
import i18next from 'i18next';


interface IProps {
  history: any;
 language: string;
}

export default class EmailSentPage extends Component<IProps, any> {
  render() {
    return (
      <div className={"bg-white content-start min-h-[80vh] dark:bg-gray-800"}>
        <div className="flex flex-col max-w-lg mx-auto w-full justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-4 px-16 pb-6">
          <div
            className="flex flex-col justify-start items-center self-stretch flex-grow relative rounded-lg"
            style={{ filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.05))" }}
          >
            <div className="flex flex-col justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-3 p-6">
              <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative">
                <p className="flex-grow-0 flex-shrink-0 dark:text-white text-2xl font-semibold text-left text-[#020617]">
                  {i18next.t("Check Your Inbox")}!
                </p>
              </div>
              <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative">
                <p className="flex-grow dark:text-gray-200 text-sm text-center text-slate-500">
                  {i18next.t("We’ve sent a password reset link to your email")}.
                  {i18next.t("Please follow the instructions to reset your password")}
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-6 px-6 pb-6">
              <div onClick={() => {this.props.history.push("/login")}} className="flex justify-center items-center self-stretch py-2 relative bg-clip-blue rounded text-white">
                <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-black">{i18next.t("Go back to Login")}</p>
              </div>
            </div>
            <svg
              width={384}
              height={317}
              viewBox="0 0 384 317"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="flex-grow-0 flex-shrink-0 w-96 h-[316.33px] relative"
              preserveAspectRatio="xMidYMid meet"
            >
              <g clip-path="url(#clip0_552_1768)">
                <path
                  d="M296.14 135.777C296.064 135.777 295.99 135.754 295.928 135.711L193.067 64.0757C192.754 63.8587 192.382 63.7427 192.001 63.7434C191.62 63.744 191.249 63.8612 190.937 64.0793L88.8171 135.71C88.7363 135.767 88.6363 135.789 88.5391 135.772C88.442 135.755 88.3555 135.7 88.2989 135.619C88.2422 135.538 88.22 135.438 88.237 135.341C88.2541 135.244 88.3091 135.157 88.3898 135.101L190.509 63.4702C190.947 63.165 191.467 63.0009 192 63C192.533 62.9991 193.054 63.1615 193.492 63.4653L296.353 135.1C296.418 135.145 296.467 135.21 296.492 135.285C296.518 135.36 296.519 135.441 296.495 135.517C296.471 135.592 296.424 135.658 296.36 135.705C296.296 135.752 296.219 135.777 296.14 135.777Z"
                  fill="#3F3D56"
                />
                <path
                  d="M94.6526 138.317L192.103 66.0941L290.292 143.456L197.124 198.687L146.541 187.157L94.6526 138.317Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M150.811 232.801H102.668C102.372 232.802 102.08 232.744 101.806 232.631C101.533 232.518 101.285 232.352 101.076 232.143C100.867 231.935 100.701 231.687 100.588 231.413C100.474 231.14 100.416 230.848 100.416 230.552C100.416 230.256 100.474 229.964 100.588 229.691C100.701 229.418 100.867 229.17 101.076 228.961C101.285 228.752 101.533 228.586 101.806 228.473C102.08 228.36 102.372 228.302 102.668 228.303H150.811C151.106 228.302 151.399 228.36 151.672 228.473C151.946 228.586 152.194 228.752 152.403 228.961C152.612 229.17 152.778 229.418 152.891 229.691C153.005 229.964 153.063 230.256 153.063 230.552C153.063 230.848 153.005 231.14 152.891 231.413C152.778 231.687 152.612 231.935 152.403 232.143C152.194 232.352 151.946 232.518 151.672 232.631C151.399 232.744 151.106 232.802 150.811 232.801Z"
                  fill="#2AAAE1"
                />
                <path
                  d="M119.941 223.131H102.668C102.372 223.131 102.08 223.074 101.806 222.961C101.533 222.848 101.285 222.682 101.076 222.473C100.867 222.264 100.701 222.016 100.588 221.743C100.474 221.47 100.416 221.177 100.416 220.882C100.416 220.586 100.474 220.294 100.588 220.02C100.701 219.747 100.867 219.499 101.076 219.29C101.285 219.082 101.533 218.916 101.806 218.803C102.08 218.69 102.372 218.632 102.668 218.633H119.941C120.236 218.632 120.529 218.69 120.802 218.803C121.075 218.916 121.324 219.082 121.533 219.29C121.742 219.499 121.908 219.747 122.021 220.02C122.134 220.294 122.193 220.586 122.193 220.882C122.193 221.177 122.134 221.47 122.021 221.743C121.908 222.016 121.742 222.264 121.533 222.473C121.324 222.682 121.075 222.848 120.802 222.961C120.529 223.074 120.236 223.131 119.941 223.131Z"
                  fill="#2AAAE1"
                />
                <path
                  d="M193.473 178.544C193.107 178.544 192.745 178.473 192.406 178.334L132.305 153.397V75.3386C132.306 74.6484 132.581 73.9866 133.069 73.4985C133.557 73.0105 134.219 72.7359 134.909 72.7351H250.207C250.897 72.7359 251.559 73.0105 252.047 73.4985C252.535 73.9866 252.81 74.6484 252.811 75.3386V153.451L252.698 153.5L194.572 178.322C194.224 178.469 193.851 178.544 193.473 178.544Z"
                  fill="white"
                />
                <path
                  d="M193.473 178.729C193.083 178.73 192.696 178.654 192.335 178.507L132.119 153.521V75.3387C132.12 74.5991 132.414 73.8901 132.937 73.3671C133.46 72.8442 134.169 72.55 134.909 72.5492H250.207C250.947 72.55 251.656 72.8442 252.179 73.3671C252.702 73.8901 252.996 74.5991 252.997 75.3387V153.574L194.645 178.493C194.274 178.649 193.876 178.73 193.473 178.729ZM132.863 153.024L192.619 177.819C193.176 178.045 193.8 178.041 194.355 177.809L252.253 153.083V75.3387C252.252 74.7963 252.036 74.2764 251.653 73.8929C251.269 73.5094 250.749 73.2937 250.207 73.2931H134.909C134.367 73.2937 133.847 73.5094 133.463 73.8929C133.08 74.2764 132.864 74.7963 132.863 75.3387L132.863 153.024Z"
                  fill="#3F3D56"
                />
                <path
                  d="M295.396 135.033H295.322L252.625 153.265L194.206 178.211C193.977 178.307 193.732 178.357 193.485 178.358C193.237 178.359 192.992 178.312 192.762 178.218L132.491 153.213L88.7448 135.063L88.678 135.033H88.6035C87.9133 135.034 87.2515 135.309 86.7634 135.797C86.2753 136.285 86.0008 136.947 86 137.637V250.704C86.0008 251.394 86.2753 252.056 86.7634 252.544C87.2515 253.032 87.9133 253.306 88.6035 253.307H295.396C296.087 253.306 296.749 253.032 297.237 252.544C297.725 252.056 297.999 251.394 298 250.704V137.637C297.999 136.947 297.725 136.285 297.237 135.797C296.749 135.309 296.087 135.034 295.396 135.033ZM297.256 250.704C297.256 251.197 297.06 251.67 296.711 252.018C296.363 252.367 295.89 252.563 295.396 252.563H88.6035C88.1104 252.563 87.6375 252.367 87.2888 252.018C86.94 251.67 86.7441 251.197 86.7439 250.704V137.637C86.7445 137.156 86.9311 136.694 87.2645 136.347C87.5979 136.001 88.0523 135.797 88.5329 135.777L132.491 154.017L192.476 178.906C193.125 179.17 193.853 179.166 194.499 178.895L252.625 154.073L295.471 135.777C295.951 135.798 296.404 136.003 296.736 136.349C297.069 136.696 297.255 137.157 297.256 137.637V250.704Z"
                  fill="#3F3D56"
                />
                <path
                  d="M192.872 156.409C190.654 156.413 188.494 155.696 186.718 154.365L186.608 154.282L163.433 136.538C162.36 135.716 161.459 134.69 160.782 133.52C160.105 132.349 159.665 131.057 159.488 129.717C159.311 128.376 159.399 127.014 159.748 125.708C160.097 124.401 160.7 123.177 161.522 122.103C162.345 121.03 163.37 120.129 164.541 119.452C165.711 118.775 167.004 118.336 168.344 118.158C169.684 117.981 171.047 118.069 172.353 118.418C173.659 118.767 174.884 119.37 175.957 120.192L190.969 131.703L226.442 85.4397C227.265 84.3666 228.291 83.4662 229.462 82.7897C230.633 82.1132 231.925 81.6739 233.266 81.497C234.606 81.32 235.968 81.4089 237.275 81.7584C238.581 82.1079 239.805 82.7113 240.878 83.5341L240.658 83.8335L240.884 83.5384C243.048 85.2019 244.465 87.656 244.822 90.3623C245.179 93.0687 244.448 95.8062 242.79 97.9743L201.064 152.391C200.099 153.645 198.858 154.66 197.437 155.356C196.017 156.053 194.455 156.413 192.872 156.409Z"
                  fill="#2AAAE1"
                />
              </g>
              <defs>
                <clipPath id="clip0_552_1768">
                  <rect width={212} height="190.307" fill="white" transform="translate(86 63)" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    )
  }
}
