import React, { Component } from 'react';
import i18next from "i18next";

import { appAuthUrl, appRedirectUrl } from "../../config";
import { Request } from "../../auth/request";
import { toggleSpinner } from "../../util";
import { Link } from 'react-router-dom';
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import SubscriptionPlan from '../../constants/plan';

interface IProps {
  history: any;
  language: string;
  isDarkMode: boolean;
}

export default class SignupPage extends Component<IProps, any> {
  state: { [key: string]: any } = {
    _signupData: {
      email: "",
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      picture: "",
      plan: SubscriptionPlan.getBasicPlanName(),
      app: "clipsynphony",
      appRedirectUrl: appRedirectUrl,
      password_confirm: "",
      trackUser: false,
      locale: window.localStorage.i18nextLng,
      recaptchaToken: "",
    },
    _signupMessage: "",
    _signupStatus: "",
    _email: "",
    _username: "",
    _password: "",
    _password_confirm: "",
    _password_confirm_pass: false,
    _signingup: false,
    _alreadySignedUp: false,
    _terms_and_conditions_check: false,
    _recaptchaInput: false,
    _disabled: true,
    password_type: "password",
    password_type_confirm: "password",
  };

  captchaRef:any;

  // function to set the captcha ref
  setCaptchaRef = (r:any) => {
    this.captchaRef = r;
  };

  render() {
    const { _disabled, _email, _username, _password, _signupStatus, _signupMessage, _password_confirm, _terms_and_conditions_check} = this.state;
    let icon = this.props.isDarkMode ? "white" : "black";
    if(this.state.password_type !== "password") {
      icon = this.props.isDarkMode ? "off-white" : "off";
    }

    let iconConfirm = this.props.isDarkMode ? "white" : "black";
    if(this.state.password_type_confirm !== "password") {
      iconConfirm = this.props.isDarkMode ? "off-white" : "off";
    }

    return (
      <div className={"bg-white dark:bg-gray-800"}>
        <div className="flex flex-col justify-start items-start w-full max-w-md mx-auto rounded-lg p-6">
          <div className="flex flex-col w-full justify-start items-center gap-4">
            <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">{i18next.t("Create an Account")}</h1>
            <p className="text-sm text-center text-gray-500 dark:text-gray-300">
              {i18next.t("Enter your information to create an account")}.
            </p>
          </div>
          <div className="flex flex-col w-full gap-6 mt-4">
            <button onClick={this.googleLogin}
                    className="flex items-center justify-center gap-2 py-2 px-4 bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-md">
              <img
                src="https://storage.googleapis.com/clipsymphony_static_assets/google-logo-png-google-icon-logo-png-transparent-svg-vector-bie-supply-14.png"
                alt="Google" className="w-4 h-4"/>
              <span
                className="text-sm font-medium text-gray-900 dark:text-white">{i18next.t("Sign up with Google")}</span>
            </button>
            <button onClick={this.facebookLogin}
                    className="flex items-center justify-center gap-2 py-2 px-4 bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-md">
              <img src="https://storage.googleapis.com/clipsymphony_static_assets/facebook.png" alt="Facebook"
                   className="w-4 h-4"/>
              <span
                className="text-sm font-medium text-gray-900 dark:text-white">{i18next.t("Sign up with Facebook")}</span>
            </button>
            <div className="flex items-center w-full gap-2">
              <div className="flex-grow h-px bg-gray-200 dark:bg-gray-600"></div>
              <span className="text-xs text-gray-500 dark:text-gray-300">{i18next.t("OR CONTINUE WITH EMAIL")}</span>
              <div className="flex-grow h-px bg-gray-200 dark:bg-gray-600"></div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col space-y-2">
                <label className="text-sm flex justify-between font-medium text-gray-900 dark:text-white">
                  <span>{i18next.t("Email")}</span>
                  <span className={`signup-error text-red-500 ${_email ? '' : 'hidden'}`}>{_email ? _email : 'email validation'} </span>
                </label>
                <input onInput={this.signUpChange} name="_email" type="email"
                       placeholder={i18next.t("Enter your email address")}
                       className="py-2 px-3 border border-gray-200 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"/>
              </div>
              <div className="flex flex-col space-y-2">
                <label className="text-sm flex justify-between font-medium text-gray-900 dark:text-white">
                  <span>{i18next.t("Username")} </span>
                  <span className={`!text-sm text-red-500 signup-error ${_username ? '' : 'hidden'}`}>{_username ? _username : 'username validation'} </span>
                </label>
                <input onInput={this.signUpChange} name="_username" type="username"
                       placeholder={i18next.t("Enter your email address")}
                       className="py-2 px-3 border border-gray-200 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"/>
              </div>
              <div className={"space-y-2"}>
                <label className="text-sm flex space-x-12 justify-between font-medium text-gray-900 dark:text-white">
                  <span>{i18next.t("Password")}</span>
                  <span className={`signup-error text-red-500 ${_password ? '' : 'hidden'}`}> {_password ? _password : 'Password must contain at least 1 number, 1 special character and longer that 6 characters'} </span>
                </label>
                <div className="relative">
                  <input onInput={this.signUpChange} name="_password" type={this.state.password_type}
                         placeholder={i18next.t("Enter your password")}
                         className="w-full py-2 px-3 border border-gray-200 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"/>
                  <img onClick={(e) => {
                    e.preventDefault();
                    this.setState({password_type: this.state.password_type === "password" ? "text" : "password"})
                  }} className="h-5 absolute right-2 top-3 w-5 cursor-pointer"
                       src={`https://storage.googleapis.com/clipsymphony_static_assets/Icons/visibile%20-%20${icon}.svg`}
                       alt="Toggle password visibility"/>
                </div>
              </div>
              <div className={"space-y-2"}>
                <label className="text-sm flex justify-between font-medium text-gray-900 dark:text-white">
                  <span>{i18next.t("Confirm Password")}</span>
                  <span className={`signup-error text-red-500 ${_password_confirm ? '' : 'hidden'}`}>{_password_confirm ? _password_confirm : 'password validation'}</span>
                </label>
                <div className="relative">
                  <input onInput={this.signUpChange} name="_password_confirm" type={this.state.password_type_confirm}
                         placeholder={i18next.t("Confirm your password")}
                         className="w-full py-2 px-3 border border-gray-200 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"/>
                  <img onClick={(e) => {
                    e.preventDefault();
                    this.setState({password_type_confirm: this.state.password_type_confirm === "password" ? "text" : "password"})
                  }} className="h-5 w-5 absolute right-2 top-3 cursor-pointer"
                       src={`https://storage.googleapis.com/clipsymphony_static_assets/Icons/visibile%20-%20${iconConfirm}.svg`}
                       alt="Toggle password visibility"/>
                </div>
              </div>
              <div className="text-left w-[78%] flex justify-start">
                <ReCAPTCHA
                  className="g-recaptcha text-left w-full"
                  sitekey="6LcxQjgpAAAAAKe7EBOCgO0KBirP2umkQqBi1B-i"
                  onChange={this.captchaChange}
                  onExpired={this.captchaExpired}
                  onError={this.captchaExpired}
                  ref={(r: any) => this.setCaptchaRef(r)}
                />
              </div>
            </div>
            {
              _signupMessage && (
                <div
                  className={`signup-message ${["200", "201"].includes(_signupStatus) ? "text-green-500" : "text-red-500"} ${_signupMessage.length ? '' : 'hidden'} text-sm capitalize mt-2`}> {i18next.t(_signupMessage)} </div>
              )
            }
            <button onClick={!_disabled ? this.signupClick : this.validateSignUp}
                    className="py-2 px-4 bg-[#27aae1] text-white rounded-md">{i18next.t("Sign up with Email")}</button>
          </div>
          <p className="mt-4 text-sm w-full text-center text-gray-500 dark:text-gray-300">
            {i18next.t("By signing up you agree to our")} <Link className={"cursor-pointer text-clip-blue"} to={"/terms-and-conditions"}> {i18next.t("Terms of Service")} </Link> {i18next.t("and")} <Link className={"cursor-pointer text-clip-blue"} to={"/privacy-policy"}>{i18next.t("Privacy Policy")}</Link>
          </p>
          <p className="mt-2 w-full text-sm text-center text-gray-900 dark:text-white">
            {i18next.t("Already have an account")} <Link to="/login" className="text-clip-blue">{i18next.t("Login")}</Link>
          </p>
        </div>
      </div>
    )
  }

  updateDefaultPlan = (plan: string) => (e: any) => {
    this.setState({_signupData: {...this.state._signupData, plan}});
  };

  terms_and_conditions_change = async (e: any) => {
    await this.setState({_terms_and_conditions_check: !this.state._terms_and_conditions_check});
    this.validateSignUp();
    return false;
  };

  user_tracking_consent_change = async (e: any) => {
    await this.setState({_signupData: {...this.state._signupData, trackUser: !this.state._signupData.trackUser}});
    return false;
  };

  signUpChange = async (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (name === "_email" && (!value || !re.test(value))) {
      await this.setState({
        [name]: i18next.t("Please enter a valid email"),
        _signupData: {...this.state._signupData, [name.toString().replace("_", "")]: value}
      });
      this.validateSignUp();
      return;
    }

    if ("_username" === name && (!value || !/^[a-zA-Z0-9]+$/.test(value))) {
      await this.setState({[name]: i18next.t("User name invalid")});
      this.validateSignUp();
      return;
    }

    if (name === "_password" && (!value || !/^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/.test(value))) {
      await this.setState({[name]: i18next.t("Password must contain at least 1 number, 1 special character and longer that 6 characters")});

      if (value === this.state._signupData.password_confirm) {
        await this.setState({_password_confirm: "", _password_confirm_pass: true})
      } else {
        await this.setState({_password_confirm: i18next.t("passwords do not match"), _password_confirm_pass: false})
      }
      this.validateSignUp();
      return;
    } else if (name === "_password" && (value && value.length > 4)) {
      if (value === this.state._signupData.password_confirm) {
        await this.setState({_password_confirm: "", _password_confirm_pass: true})
      } else {
        await this.setState({_password_confirm: i18next.t("passwords do not match"), _password_confirm_pass: false})
      }
    }

    if (name === "_password_confirm" && (!value || value !== this.state._signupData.password)) {
      await this.setState({[name]: i18next.t("passwords do not match"), _password_confirm_pass: false});
      this.validateSignUp();
      return;
    } else if (name === "_password_confirm" && (value && value === this.state._signupData.password)) {
      await this.setState({[name]: "", _password_confirm_pass: true});
    }

    await this.setState({
      [name]: "",
      _signupData: {...this.state._signupData, [name.toString().replace("_", "")]: value}
    });
    this.validateSignUp();
  };

  validateSignUp = () => {
    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!this.state._password_confirm && this.state._password_confirm_pass && re.test(this.state._signupData.email) && !this.state._password && !this.state._username && this.state._recaptchaInput) {
      this.setState({_signupStatus: "", _signupMessage: "", _disabled: false, _alreadySignedUp: false})
    } else {
      this.setState({
        _signupStatus: "red",
        _signupMessage: i18next.t("input all fields"),
        _disabled: true,
        _alreadySignedUp: false
      })
    }
  };

  signupClick = async (e: any) => {
    e.preventDefault();
    if (this.state._alreadySignedUp) return;
    try {
      toggleSpinner();
      const res = await Request.post(`${appAuthUrl}/signup${window.location.search}`, {
        ...this.state._signupData,
        locale: window.localStorage.i18nextLng
      });
      this.captchaRef.reset();
      toggleSpinner();
      this.setState({_signupStatus: res.status, _signupMessage: res.message, _alreadySignedUp: true})
    } catch (e) {
      toggleSpinner();
    }
  };

  googleLogin(e: any) {
    e.preventDefault();
    localStorage.setItem("loginItem", "google");
    window.location.href = `${appAuthUrl}/auth/google?provider=google`
  }

  captchaChange = async (res: any) => {
    await this.setState({_signupData: {...this.state._signupData, recaptchaToken: res}});
    await this.setState({_recaptchaInput: true});
    this.validateSignUp();
    return false;
  };

  captchaExpired = async (e: any) => {
    await this.setState({_signupData: {...this.state._signupData, recaptchaToken: ""}});
    await this.setState({_recaptchaInput: false});
    this.validateSignUp();
    return false;
  };

  componentWillMount(): void {
    toggleSpinner();
    const token = localStorage.getItem("token");
    if (token) {
      return window.location.replace(`/app/login?token=${token}`)
    } else {
      toggleSpinner();
    }
    // get the query for the plan and if it's professional, activate premium
    const urlParams = new URLSearchParams(window.location.search);
    const plan = urlParams.get('plan');
    if (plan === SubscriptionPlan.getProfessionalPlanName() || plan == SubscriptionPlan.getEnterprisePlanName()) {
      this.updateDefaultPlan(plan)(null);
      const baseUrl = window.location.href.split('?')[0];
      window.history.replaceState({}, document.title, baseUrl);
    }
  }

  facebookLogin(e: any) {
    e.preventDefault();
    localStorage.setItem("loginItem", "facebook");
    window.location.href = `${appAuthUrl}/auth/facebook?provider=facebook`
  }
}
