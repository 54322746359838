import React, { Component } from 'react';
import "./confirmation-problem.scss";
import { Link } from 'react-router-dom';
import i18next from 'i18next';


interface IProps {
  history: any;
  language: string;
}
export default class AppConfirmationProblem extends Component<IProps, any> {
  render() {
    return <>
      <div className="privacy-terms">
        <div className="sub-text">
          <h2 className="title"> {i18next.t("Email Verification Failed")} </h2>
          <br/>
          <p>
            {i18next.t("Please try again later, or enter your email to re-send the verification link \nIf you still have trouble verifying your email")}
            <br/> <a href="/contact"> {i18next.t("contact customer support")} </a>
            <div className="send-button"> <Link to={"/"}> {i18next.t("Go to Login Page")} </Link> </div>
          </p>
        </div>
      </div>
    </>;
  }
}
