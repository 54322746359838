import React, { Component } from 'react';
import { Link } from "react-router-dom";
import i18next from 'i18next';


interface IProps {
  history: any;
  language: string;
}

export default class AppLinkExpired extends Component<IProps, any> {
  render() {
    return (
      <div className="flex items-start justify-center min-h-screen bg-gray-100 dark:bg-gray-800">
        <div
          className="w-[60%] mx-auto bg-white dark:bg-gray-800 p-6 rounded-lg mt-8 text-gray-900 dark:text-gray-100">
          <div className="text-center">
            <h2 className="text-2xl font-semibold mb-4">{i18next.t("This verification link is expired or invalid")}</h2>
            <p className="mb-6">
              {i18next.t("This link doesn’t seem to be working, most likely due to its expiration. If your e-mail is not verified yet, please enter your email to re-send the verification link. Note that the verification link is valid only for 24 hours.")}
            </p>
            <div className="text-center">
              <Link to="/" className="text-clip-blue hover:underline">
                {i18next.t("Go to Login Page")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
