import React, { Component } from 'react';
import "./new-link-sent.scss";
import { Link } from 'react-router-dom';
import i18next from 'i18next';

interface IProps {
  history: any;
  language: string;
}

export default class AppNewLinkSent extends Component<IProps, any> {
  render() {
    return <>
      <div className="privacy-terms">
        <div className="sub-text">
          <h2 className="title"> {i18next.t("Email Sent")} </h2>
          <p>
            {i18next.t("A new verification link is sent to your email, please check your inbox\nNote that the verification link is valid only for 24 hours")}
            <div className="send-button"><Link to={"/"}> {i18next.t("Go to Login Page")} </Link></div>
          </p>
        </div>
      </div>
    </>
  }
}
