
import { Reducer } from 'redux';
import {
  UPDATE_PAGE,
  LOGGED_IN,
  TOKEN_UPDATE,
  Token,
  TRANSLATION_LOADED,
  PARAMS_UPDATE,
  Params,
  BATCH_UPDATE,
  UPDATE_TEAMS,
  Team,
} from '../actions/app';
import { RootAction } from '../store';


export interface AppState {
  page: string;
  loggedIn: boolean;
  params: Params;
  token: Token;
  translationLoaded: boolean;
  teams: Team[];
}

const INITIAL_STATE: AppState = {
  page: '/',
  loggedIn: false,
  token: {token: "", expires: ""},
  params: {},
  translationLoaded: false,
  teams: [],
};

const app: Reducer<AppState, RootAction> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_PAGE:
      return { ...state, page: action.page };
    case LOGGED_IN:
      return { ...state, loggedIn: action.loggedIn };
    case PARAMS_UPDATE:
      return {...state, params: action.paramsUpdate};
    case TRANSLATION_LOADED:
      return { ...state, translationLoaded: action.translationLoaded };
    case TOKEN_UPDATE:
      return { ...state, token: action.tokenUpdate };
    case UPDATE_TEAMS:
      return { ...state, teams: action.teams };
    case BATCH_UPDATE:
      // @ts-ignore
      return { ...state, ...action.batch };
    default:
      return state;
  }
};

export default app;
