import { Component } from 'react';
import i18next from "i18next";

import {appAuthUrl} from "../../config";
import {Request} from "../../auth/request";
import { Team } from '../../actions/app';

import "./select-team.scss"


interface IProps {
  history: any;
  language: string;
}
export default class SelectTeamPage extends Component<IProps, any> {
  private _translationLoaded = false;
  private teams = [];
  private __token = "";
  private _language = 'en';

  render() {
    return (
      <div id="select-team-page">
          <div className="team-list py-3">
              {
                this.teams.map((team: Team, index: number) => (
                  <div key={index} className="team-item" onClick={this.setDefaultTeam(team)}>
                    <section> <article>TEAM:</article> ${team.name}</section>
                    <section> <article>ORGANISATION:</article> ${team.organisation_name}</section>
                  </div>
                ))
              }
          </div>
      </div>
    )
  }

  setDefaultTeam = (team: Team) => async(e: any) => {
    const res = await Request.getWithAuth(`${appAuthUrl.replace("user", "")}assign-default-team/${team.id}`, this.__token);
    return window.location.replace(`/app/login?token=${res.token}&expires=${res.expires}&scope=${res.scope}`)
  };
}
