import { Component } from 'react';
import "./already-confirmed.scss"
import { Link } from 'react-router-dom';
import i18next from 'i18next';


interface IProps {
  history: any;
  language: string;
}

export default class AppAlreadyConfirmed extends Component<IProps, any> {
  render() {
    return <div className="flex items-start justify-center min-h-[90vh] bg-gray-100 dark:bg-gray-800">
      <div
        className="max-w-md w-full mt-8 dark:bg-gray-800 p-6 rounded-lg text-gray-900 dark:text-gray-100">
        <div className="sub-text text-center">
          <h2
            className="title text-xl font-semibold mb-4">{i18next.t("This verification link is expired or invalid")}</h2>
          <p className="mb-6">
            {i18next.t("However, your e-mail address for your Clipsymphony account has already been verified. Therefore you can ignore this message")}
          </p>
          <div className="send-button">
            <Link to="/" className="text-clip-blue hover:underline">
              {i18next.t("Go to Login Page")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  }
}
