import React from "react";
import {Router, Route, Switch, BrowserRouter} from "react-router-dom";
import { createBrowserHistory } from 'history';
import AppIndex from '../app-index/app-index';


export const history = createBrowserHistory({basename: "/app/"});
export default function Routes() {
  return (
    <Router history={history}>
      <div>
        <Switch>
          <Route path="/"> <AppIndex history={history}/> </Route>
          <Route path="*"> <AppIndex history={history}/> </Route>
        </Switch>
      </div>
    </Router>
  );
}
