import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import i18next from 'i18next';
import { Request } from '../../auth/request';
import { appComplaintUrl } from '../../config';
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";

import "./contact-complaint.scss";

interface IProps {
	history?: any;
	language: string;
	className?: string;
}

export function ComplaintForm(props: IProps) {
	//@ts-ignore
	const { tempId } = useParams();
	let { search } = useLocation();
	let qry = new URLSearchParams(search);
	const email = qry.get("email");
	const [formTouched, setFormTouched] = useState<boolean>(false);
	const [messageSent, setMessageSent] = useState<boolean>(false);
	const [messageSentError, setMessageSentError] = useState<string>("");
	const [message, setMessage] = useState<string>("");
	const [recaptchaSolved, setRecaptchaSolved] = useState<boolean>(false);
	const [recaptchaValue, setRecaptchaValue] = useState<string>("");
	const [disableButton, setDisableButton] = useState<boolean>(true);

	if(!email) {
		return (
			<div className="flex items-start justify-center min-h-[90vh] bg-gray-100 dark:bg-gray-800">
				<div
					className="max-w-md w-full p-6 mt-8 rounded-lg text-gray-900 dark:text-gray-100">
					<div className="sub-text text-center">
						<h2 className="title text-xl font-semibold mb-4">{i18next.t("Invalid Request Form")}</h2>
						<p className="mb-6">
							{i18next.t("Request form is invalid. Please submit another complaint request.")}
						</p>
						<div className="send-button">
							<a
								href="/"
								onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
									e.preventDefault();
									window.location.href = "/";
								}}
								className="text-clip-blue hover:underline"
							>
								{i18next.t("Go to Home Page")}
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}

	const submitForm = (e: any) => {
		// check for errors
		Request
			.put(`${appComplaintUrl}/public/contacts-complaints/${tempId}`, {
				email: email,
				type: "Complaint",
				tempId: tempId,
				message: message,
				recaptchaToken: recaptchaValue,
			}).then((res: any) => {
			if (res.error) {
				setMessageSent(true);
				setMessageSentError(res.message);
			} else {
				setMessageSent(true);
			}
		}).catch((err: any) => {
			setMessageSent(true);
			setMessageSentError("Error processing complaint. please try again later");
		})
	};

	const change = (e: any) => {
		setMessage(e.target.value);
		setFormTouched(true);
		if (e.target.value.length > 3 && recaptchaSolved) {
			setDisableButton(false);
		} else {
			setDisableButton(true);
		}
	};

	const captchaChange = async (res:any) => {
		await setRecaptchaSolved(true);
		await setRecaptchaValue(res);
		if(message.length > 3) {
			setDisableButton(false);
		} else {
			setDisableButton(true);
		}
	};

	const captchaExpired = async (e:any) => {
		await setRecaptchaValue("");
		await setRecaptchaSolved(false);
		await	setDisableButton(true);
	};

	let component = null;
	// message was sent successfully
	if(messageSent && !messageSentError) {
		component = (
			<div className="flex items-start justify-center min-h-[90vh] bg-gray-100 dark:bg-gray-800">
				<div className="max-w-md w-full p-6 mt-8 rounded-lg text-gray-900 dark:text-gray-100">
					<div className="sub-text text-center">
						<h2 className="title text-xl font-semibold mb-4">{i18next.t("Message Sent")}</h2>
						<p className="mb-6">
							{i18next.t("Thank you for contacting the author, your message is successfully sent")}
						</p>
						<div className="send-button">
							<a
								href="/"
								onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
									e.preventDefault();
									window.location.href = "/";
								}}
								className="text-clip-blue hover:underline"
							>
								{i18next.t("Go to Home Page")}
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}

	// if there's an error sending the message
	if (messageSent && messageSentError) {
		component = (
			<div className="flex items-start justify-center min-h-[90vh] bg-gray-100 dark:bg-gray-800">
				<div className="max-w-md w-full p-6 mt-8 rounded-lg text-gray-900 dark:text-gray-100">
					<div className="sub-text text-center">
						<h2 className="title text-xl font-semibold mb-4">{i18next.t("Error Sending Message!")}</h2>
						<p className="mb-6">
							{i18next.t(messageSentError)}
						</p>
						<div className="send-button">
							<a
								href="/"
								onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
									e.preventDefault();
									window.location.href = "/";
								}}
								className="text-clip-blue hover:underline"
							>
								{i18next.t("Go to Home Page")}
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}

	// if the component above is still null
	// then let's assign a default
	if (!component) {
		component = (
			<div className="contact min-h-[90vh]">
				<div className="contact-intro mb-4">
					<h1
						className="text-4xl font-semibold text-gray-900 dark:!text-white"> {i18next.t("Complaint Form")} </h1>
				</div>
				<div className="form w-full max-w-lg mx-auto">
					<div className="flex flex-col space-y-2">
						<label className="text-sm font-medium text-gray-900 dark:text-white">{i18next.t("Email")}</label>
						<input value={email} name="_email" disabled type="email"
						       placeholder={email}
						       className="py-2 px-3 border border-gray-200 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"/>
					</div>
					<div className="flex flex-col space-y-2">
						<label className="text-sm font-medium text-gray-900 dark:text-white">{i18next.t("Message")}</label>
						<textarea onChange={change} placeholder={i18next.t("Message")}
						          className="py-2 px-3 border border-gray-200 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"/>
						{formTouched && message.length < 4 && <span className={"text-red-500"}>{i18next.t("message must be greater than 3 characters")}</span>}
					</div>
					{/*<div>*/}
					{/*	<textarea onChange={change} placeholder={i18next.t("Message")}/>*/}
					{/*</div>*/}
					<div className="captchaContainer flex items-start">
						{/*<div className="g-recaptcha" data-sitekey="6LcxQjgpAAAAAKe7EBOCgO0KBirP2umkQqBi1B-i" data-callback="onSubmit" data-action="homepage" />*/}
						<ReCAPTCHA
							className={"g-recaptcha"}
							sitekey="6LcxQjgpAAAAAKe7EBOCgO0KBirP2umkQqBi1B-i"
							onChange={captchaChange}
							onExpired={captchaExpired}
							onError={captchaExpired}
						/>
					</div>
					<div className={"!block !w-full"}>
						<button className={"!block !w-full"} disabled={disableButton}
						        onClick={submitForm}> {i18next.t("Send Message")} </button>
					</div>
				</div>
			</div>
		)
	}

	return component
}
