import React from 'react';
import { store } from './store';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import Router from './components/router/router';
import reportWebVitals from './reportWebVitals';

import './index.scss';
import "./scss/fonts.scss";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
