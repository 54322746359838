import i18next from "i18next";
import React, { Component } from 'react';
import { Request } from "../../auth/request";
import { toggleSpinner } from "../../util";
import {appAuthUrl} from "../../config";
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import {Link} from "react-router-dom";


interface IProps {
  history: any;
  language: string;
  isDarkMode: boolean;
}
export default class PasswordResetPage extends Component<IProps, any> {
  state: {[key:string]: any} = {
    _resetData: { password: "", app: "clipsynphony", password_confirm: "", email: "", resetId: "", recaptchaToken: "",},
    _translationLoaded: false,
    _password: "",
    _password_confirm: "",
    _password_confirm_pass: false,
    _recaptchaInput: false,
    _disabled: true,
    _resetMessage: "",
    _responseMessage: "",
    _resetStatus: "",
    _language: 'en',
    password_type: "password",
    password_type_confirm: "password",
  };

  render() {

    const {_password, _password_confirm, _resetMessage, _responseMessage, _resetStatus, _disabled} = this.state;

    let icon = this.props.isDarkMode ? "white" : "black";
    if(this.state.password_type !== "password") {
      icon = this.props.isDarkMode ? "off-white" : "off";
    }

    let iconConfirm = this.props.isDarkMode ? "white" : "black";
    if(this.state.password_type_confirm !== "password") {
      iconConfirm = this.props.isDarkMode ? "off-white" : "off";
    }

    return (
      <div className={"bg-white content-start min-h-[90vh] dark:bg-gray-800"}>
        <div
          className="flex flex-col justify-start items-start w-full mt-12 max-w-md mx-auto bg-white dark:bg-gray-800 rounded-lg">
          <div className="flex flex-col justify-start items-center w-full gap-4 px-6 py-8">
            <h1 className="text-4xl font-semibold text-gray-900 dark:text-white">{i18next.t("Reset Password")}</h1>
            <p
              className="text-sm text-center text-gray-600 dark:text-gray-400">{i18next.t("Create a new password for your account")}</p>
            <div className="w-full space-y-4 mt-4">
              <div className={"space-y-2"}>
                <label className="text-sm flex space-x-12 justify-between font-medium text-gray-900 dark:text-white">
                  <span>{i18next.t("Password")}</span>
                  <span
                    className={`signup-error text-red-500 ${_password ? '' : 'hidden'}`}> {_password ? _password : 'Password must contain at least 1 number, 1 special character and longer that 6 characters'} </span>
                </label>
                <div className="relative">
                  <input onInput={this.resetPasswordChange} name="_password" type={this.state.password_type}
                         placeholder={i18next.t("Enter your password")}
                         className="w-full py-2 px-3 border border-gray-200 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"/>
                  <img onClick={(e) => {
                    e.preventDefault();
                    this.setState({password_type: this.state.password_type === "password" ? "text" : "password"})
                  }} className="h-5 absolute right-2 top-3 w-5 cursor-pointer"
                       src={`https://storage.googleapis.com/clipsymphony_static_assets/Icons/visibile%20-%20${icon}.svg`}
                       alt="Toggle password visibility"/>
                </div>
              </div>
              <div className={"space-y-2"}>
                <label className="text-sm flex justify-between font-medium text-gray-900 dark:text-white">
                  <span>{i18next.t("Confirm Password")}</span>
                  <span
                    className={`signup-error text-red-500 ${_password_confirm ? '' : 'hidden'}`}>{_password_confirm ? _password_confirm : 'password validation'}</span>
                </label>
                <div className="relative">
                  <input onInput={this.resetPasswordChange} name="_password_confirm" type={this.state.password_type_confirm}
                         placeholder={i18next.t("Confirm your password")}
                         className="w-full py-2 px-3 border border-gray-200 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"/>
                  <img onClick={(e) => {
                    e.preventDefault();
                    this.setState({password_type_confirm: this.state.password_type_confirm === "password" ? "text" : "password"})
                  }} className="h-5 w-5 absolute right-2 top-3 cursor-pointer"
                       src={`https://storage.googleapis.com/clipsymphony_static_assets/Icons/visibile%20-%20${iconConfirm}.svg`}
                       alt="Toggle password visibility"/>
                </div>
              </div>
              <div className="flex justify-start">
                <ReCAPTCHA
                  className={"g-recaptcha"}
                  sitekey="6LcxQjgpAAAAAKe7EBOCgO0KBirP2umkQqBi1B-i"
                  onChange={this.captchaChange}
                  onExpired={this.captchaExpired}
                  onError={this.captchaExpired}
                />
              </div>
              <div
                className={`signup-message capitalize ${["200", "201"].includes(_resetStatus) ? "text-green-500" : "text-red-500"} ${(_resetMessage || _responseMessage) ? '' : 'hidden'} error mt-2`}>
                {(_resetMessage || _responseMessage) && <div className={`signup-message error2 signup_${_resetStatus} mt-2 ${_responseMessage ? 'success' : ''}`}>{i18next.t(_responseMessage ? _responseMessage : _resetMessage)}</div>}
              </div>
              <div className="w-full mt-4">
                <button onClick={!_disabled ? this.resetPasswordClick : this.validateSignUp}
                        className="py-2 w-full text-center block px-4 bg-[#27aae1] text-white rounded-md">{i18next.t("Reset Password")}</button>
              </div>
              <div className="text-center text-black dark:!text-white text-sm mt-4">
                {i18next.t("Get Back to ")}
                <Link to="/" className="text-clip-blue text-sm hover:underline">{i18next.t("Login")}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  resetPasswordChange = async (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "_password" && (!value || !/^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/.test(value))) {
      await this.setState({[name]: i18next.t("Password must contain at least 1 number, 1 special character and longer that 6 characters")});

      if (value === this.state._resetData.password_confirm) {
        await this.setState({_password_confirm: "", _password_confirm_pass: true});
      } else {
        await this.setState({_password_confirm: i18next.t("Passwords do not match"), _password_confirm_pass: false});
      }
      this.validateSignUp();
      return;
    } else if (name === "_password" && (value && value.length > 4)) {
      if (value === this.state._resetData.password_confirm) {
        await this.setState({_password_confirm: "", _password_confirm_pass: true});
      } else {
        await this.setState({_password_confirm: i18next.t("Passwords do not match"), _password_confirm_pass: false});
      }
    }

    if (name === "_password_confirm" && (!value || value !== this.state._resetData.password)) {
      await this.setState({[name]: i18next.t("Passwords do not match"), _password_confirm_pass: false});
      this.validateSignUp();
      return;
    } else if (name === "_password_confirm" && (value && value === this.state._resetData.password)) {
      await this.setState({[name]: "", _password_confirm_pass: true});
    }

    await this.setState({
      [name]: "",
      _resetData: {...this.state._resetData, [name.toString().replace("_", "")]: value}
    });
    this.validateSignUp();
  };

  validateSignUp = () => {
    if (this.state._password_confirm_pass && this.state._resetData.password && this.state._recaptchaInput) {
      this.setState({_disabled: false, _resetMessage: "", _resetStatus: ""})
    } else {
      this.setState({_disabled: true, _resetMessage: i18next.t(i18next.t("input all fields")), _resetStatus: "red"})
    }
  };

  resetPasswordClick = async (e: any) => {
    e.preventDefault();
    try {
      toggleSpinner();
      const res = await Request.post(`${appAuthUrl}/password-reset/change`, this.state._resetData);
      toggleSpinner();

      if (res && res.status === 201) {
        this.setState({_responseMessage: res.message || "Password successfully updated", _resetStatus: "200"});
        setTimeout(() => { this.props.history.push("/") }, 5000);
      } else {
        this.setState({_resetMessage: res.message || "Error updating password"});
      }

    } catch (e) {
      toggleSpinner();
      this.setState({_resetMessage: i18next.t("password reset failed")});
    }
  };

  componentWillMount() {
    // TODO: get the reset id
    const params = new URLSearchParams(window.location.search);
    if(!params.get("resetId")) return this.props.history.push("/");
    this.setState({_resetData: {...this.state._resetData, resetId: params.get("resetId") || "", email: params.get("email") || ""}})
  }

  captchaChange = async (res:any) => {
    await this.setState({_resetData: {...this.state._resetData, recaptchaToken: res}});
    await this.setState({_recaptchaInput: true});
    this.validateSignUp();
    return false;
  };

  captchaExpired = async (e:any) => {
    await this.setState({_resetData: {...this.state._resetData, recaptchaToken: ""}});
    await this.setState({_recaptchaInput: false});
    this.validateSignUp();
    return false;
  };
}
