
import { Action, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../store';

export const UPDATE_PAGE = 'UPDATE_PAGE';
export const LOGGED_IN = 'LOGGED_IN';
export const TOKEN_UPDATE = 'TOKEN_UPDATE';
export const PARAMS_UPDATE = 'PARAMS_UPDATE';
export const UPDATE_TEAMS = 'UPDATE_TEAMS';
export const BATCH_UPDATE = 'PARAMS_UPDATE';
export const TRANSLATION_LOADED = 'TRANSLATION_LOADED';

export interface Token { token: string; expires: string }
export interface Team {
  app: string;
  created_at: string;
  created_by: string;
  id: string;
  members: string[];
  name: string;
  organisation_id: string;
  organisation_name: string;
  update_at: string;
}
export interface Params { [key: string]: string }
export interface Batch { [key: string]: any }
export interface AppActionUpdatePage extends Action<'UPDATE_PAGE'> {page: string}
export interface AppActionLoggedIn extends Action<'LOGGED_IN'> {loggedIn: boolean}
export interface AppActionTeams extends Action<'UPDATE_TEAMS'> {teams: Team[]}
export interface AppTranslationLoaded extends Action<'TRANSLATION_LOADED'> {translationLoaded: boolean}
export interface AppActionTokenUpdate extends Action<'TOKEN_UPDATE'> {tokenUpdate: Token}
export interface AppActionParamsUpdate extends Action<'PARAMS_UPDATE'> {paramsUpdate: Params}
export interface AppActionBatchUpdate extends Action<'BATCH_UPDATE'> {[key: string]: any}
export type AppAction = AppActionUpdatePage | AppActionLoggedIn |
  AppActionTokenUpdate | AppTranslationLoaded |
  AppActionParamsUpdate | AppActionBatchUpdate | AppActionTeams;

type ThunkResult = ThunkAction<void, RootState, undefined, AppAction>;

export const navigate: ActionCreator<ThunkResult> = (path: string) => (dispatch:any) => {
  let pathname = window.location.pathname;

  if(new URLSearchParams(window.location.search).get('redirectUrl')) {
    pathname = 'confirm';
  }

  if(new URLSearchParams(window.location.search).get('resetId')) {
    pathname = 'reset-password';
  }

  if(path === "select-team" || pathname === "/app/select-team") {
    pathname = "select-team"
  }

  if(path === "signup" || pathname === "/app/signup") {
    pathname = "signup"
  }

  if(path === "forgot-password" || pathname === "/app/forgot-password") {
    pathname = "forgot-password"
  }

  if(path === "link-sent" || pathname === "/app/link-sent") {
    pathname = "link-sent"
  }

  if(path === "successful-confirmation" || pathname === "/app/successful-confirmation") {
    pathname = "successful-confirmation"
  }

  if(path === "confirmation-problem" || pathname === "/app/confirmation-problem") {
    pathname = "confirmation-problem"
  }

  if(path === "already-confirmed" || pathname === "/app/already-confirmed") {
    pathname = "already-confirmed"
  }

  if(path === "link-expired" || pathname === "/app/link-expired") {
    pathname = "link-expired"
  }

  if(path === "terms-and-conditions" || pathname === "/app/terms-and-conditions") {
    pathname = "terms-and-conditions"
  }

  if(path === "privacy-policy" || pathname === "/app/privacy-policy") {
    pathname = "privacy-policy"
  }

  if(path === "reset-password" || pathname === "/app/reset-password") {
    if(new URLSearchParams(window.location.search).get('resetToken')) {
      pathname = 'reset-password';
    } else {
      pathname = "reset-password"
    }
  }

  if(path === "email-sent" || pathname === "/app/email-sent") {
    pathname = "email-sent"
  }

  if(!path || path === "login" || pathname === "/app/" || pathname === "/app" || pathname === "/app/login" || pathname === "/" || path === "/") {
    pathname = "login"
  }

  dispatch(loadPage(pathname));
};

const loadPage: ActionCreator<ThunkResult> = (page: string) => (dispatch: any) => {
  // get the params from the redirected confirm search
  const params = new URLSearchParams(window.location.search);

  if(page === 'confirm') {
    const redirectUrl = params.get('redirectUrl');
    const confirmEmail = params.get('confirmEmail');
    const mailId = params.get('mailId');

    // update the params
    dispatch(updateParams({redirectUrl, confirmEmail, mailId}));
  }

  if(page === 'reset-password') {
    const userEmail = params.get('email');
    const resetId = params.get('resetId');

    // update the params
    dispatch(updateParams({ resetId, userEmail }));
  }

  // update the current page then
  window.history.pushState({page: "/"}, `App ${page}`, `${ page === "login" ? "/app" : "/app/" + page }`);

  // update the page
  dispatch(updatePage(page));
};

const updatePage: ActionCreator<AppActionUpdatePage> = (page: string) => {
  return {  type: UPDATE_PAGE, page };
};

const updateParams: ActionCreator<AppActionParamsUpdate> = (params: Params) => {
  return {
    type: PARAMS_UPDATE,
    paramsUpdate: params
  };
};

const updateBatch: (batch: Batch) => { batch: Batch; type: string } = (batch: Batch) => {
  return {
    type: BATCH_UPDATE,
    batch
  };
};

export const logIn: ActionCreator<ThunkResult> = (loggedIn: boolean) => (dispatch:any) => {
  dispatch({ type: LOGGED_IN, loggedIn });
};

export const updateToken: ActionCreator<ThunkResult> = (token: Token) => (dispatch:any) => {
  dispatch({ type: TOKEN_UPDATE, tokenUpdate: token });
};

export const paramsUpdate: ActionCreator<ThunkResult> = (params: Params) => (dispatch:any) => {
  dispatch({ type: PARAMS_UPDATE, paramsUpdate: params });
};

export const teamsUpdate: ActionCreator<ThunkResult> = (teams: Team[]) => (dispatch:any) => {
  dispatch({ type: UPDATE_TEAMS, teams });
};

export const batchUpdate: ActionCreator<ThunkResult> = (batch: Batch) => (dispatch:any) => {
  // @ts-ignore
  return { type: BATCH_UPDATE, batch };
};

export const initTranslatedLoaded: ActionCreator<ThunkResult> = (loaded: boolean) => (dispatch:any) => {
  dispatch({type: TRANSLATION_LOADED, translationLoaded: loaded})
};
