import React, { Component } from 'react';
import i18next from "i18next";
import { appAuthUrl } from "../../config";
import { Request } from "../../auth/request";
import { toggleSpinner } from "../../util";
import { Link } from 'react-router-dom';

interface IProps {
  history: any;
  language: string;
  isDarkMode: boolean;
}

export default class ConfirmSignupLoginPage extends Component<IProps, any> {
  state = {
    _loginData: { email: "", password: "", password_confirm: "", app: "clipsynphony" },
    _password: "",
    _password_confirm: "",
    _password_confirm_pass: false,
    _loginState: false,
    _translationLoaded: false,
    _signingup: false,
    _loginMessage: "",
    _email: "",
    _redirect: "",
    _mailId: "",
    _language: 'en',
    _valid: false,
    _signupMessage: "",
    _signupStatus: "",
    _disabled: true,
    _terms_and_conditions_check: false,
    password_type: "password",
    password_type_confirm: "password",
  };

  render() {
  	const {_password, _disabled, _password_confirm, _signupMessage, _signupStatus, _loginData: {email}, _terms_and_conditions_check} = this.state;

    let icon = this.props.isDarkMode ? "white" : "black";
    if(this.state.password_type !== "password") {
      icon = this.props.isDarkMode ? "off-white" : "off";
    }

    let iconConfirm = this.props.isDarkMode ? "white" : "black";
    if(this.state.password_type_confirm !== "password") {
      iconConfirm = this.props.isDarkMode ? "off-white" : "off";
    }

    return (
      <div className={"bg-white content-start min-h-[90vh] dark:bg-gray-800"}>
        <div
          className="flex flex-col justify-start items-start w-full mt-12 max-w-md mx-auto bg-white dark:bg-gray-800 rounded-lg">
          <div className="flex flex-col justify-start items-center w-full gap-4 px-6 py-8">
            <h1 className="text-4xl font-semibold text-gray-900 dark:text-white">{i18next.t("Confirm Invite")}!</h1>
            <p
              className="text-sm text-center text-gray-600 dark:text-gray-400">{i18next.t("please enter password for access")}</p>
            <div className="w-full space-y-4 mt-4">
              <div className="flex flex-col space-y-2">
                <label className="text-sm font-medium text-gray-900 dark:text-white">{i18next.t("Email")}</label>
                <input onInput={this.emailChange} value={this.state._email} name="_email" disabled type="email" placeholder={email}
                       className="py-2 px-3 border border-gray-200 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"/>
              </div>
              <div className={"space-y-4"}>
                <label className="text-sm flex space-x-12 justify-between font-medium text-gray-900 dark:text-white">
                  <span>{i18next.t("Password")}</span>
                  <span
                    className={`signup-error text-red-500 ${_password ? '' : 'hidden'}`}> {_password ? _password : 'Password must contain at least 1 number, 1 special character and longer that 6 characters'} </span>
                </label>
                <div className="relative">
                  <input onInput={this.signUpChange} name="_password" type={this.state.password_type}
                         placeholder={i18next.t("Enter your password")}
                         className="w-full py-2 px-3 border border-gray-200 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"/>
                  <img onClick={(e) => {
                    e.preventDefault();
                    this.setState({password_type: this.state.password_type === "password" ? "text" : "password"})
                  }} className="h-5 absolute right-2 top-3 w-5 cursor-pointer"
                       src={`https://storage.googleapis.com/clipsymphony_static_assets/Icons/visibile%20-%20${icon}.svg`}
                       alt="Toggle password visibility"/>
                </div>
              </div>
              <div className={"space-y-4"}>
                <label className="text-sm flex justify-between font-medium text-gray-900 dark:text-white">
                  <span>{i18next.t("Confirm Password")}</span>
                  <span
                    className={`signup-error text-red-500 ${_password_confirm ? '' : 'hidden'}`}>{_password_confirm ? _password_confirm : 'password validation'}</span>
                </label>
                <div className="relative">
                  <input onInput={this.signUpChange} name="_password_confirm"
                         type={this.state.password_type_confirm}
                         placeholder={i18next.t("Confirm your password")}
                         className="w-full py-2 px-3 border border-gray-200 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white"/>
                  <img onClick={(e) => {
                    e.preventDefault();
                    this.setState({password_type_confirm: this.state.password_type_confirm === "password" ? "text" : "password"})
                  }} className="h-5 w-5 absolute right-2 top-3 cursor-pointer"
                       src={`https://storage.googleapis.com/clipsymphony_static_assets/Icons/visibile%20-%20${iconConfirm}.svg`}
                       alt="Toggle password visibility"/>
                </div>
              </div>
              <div className="login_relative__35Exf form-group m-1 p-0">
                <label className="checkbox">
                  <input className={"rounded"} onChange={this.terms_and_conditions_change} id="remember_me" type="checkbox" name="remember"
                         checked={_terms_and_conditions_check}/>
                  <span className="control-indicator"> &nbsp; {i18next.t('I agree to the')}
                    <Link to={"terms-and-conditions"}> {i18next.t('Terms and Conditions')}</Link>
                  </span>
                </label>
              </div>
            </div>
            {_signupMessage && <div className={`signup-message block w-full capitalize text-red-500 text-left signup_${_signupStatus} mt-2`}>{_signupMessage}</div>}
            <div className="block w-full">
              <div><input onClick={!_disabled ? this.loginClick : this.validateSignUp} name="submit" className="bg-clip-blue rounded !block !w-full py-2" type="submit" value={i18next.t('Signup').toString()}/></div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  emailChange(e: any) {
    // @ts-ignore
    this._loginData.email = e.target.value;
  }

  terms_and_conditions_change = async (e: any) => {
    await this.setState({_terms_and_conditions_check: !this.state._terms_and_conditions_check});
    this.validateSignUp();
    return false;
  };

  signUpChange = async (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "_password" && (!value || !/^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/.test(value))) {
      await this.setState({[name]: i18next.t("Password must contain at least 1 number, 1 special character and longer that 6 characters")});
      if (value === this.state._loginData.password_confirm) {
        await this.setState({_password_confirm: "", _password_confirm_pass:true});
      } else {
        await this.setState({_password_confirm: i18next.t("passwords do not match"), _password_confirm_pass:false});
      }
      this.validateSignUp();
      return;
    } else if (name === "_password" && (value && value.length > 4)) {
      if (value === this.state._loginData.password_confirm) {
        await this.setState({_password_confirm: "", _password_confirm_pass:true});
      } else {
        await this.setState({_password_confirm: i18next.t("passwords do not match"), _password_confirm_pass:false});
      }
    }

    if(name === "_password_confirm" && (!value || value !== this.state._loginData.password)) {
    	await this.setState({[name]: i18next.t("passwords do not match"), _password_confirm_pass: false});
    	this.validateSignUp();
    	return;
    } else if(name === "_password_confirm" && (value && value === this.state._loginData.password)) {
    	await this.setState({[name]: "", _password_confirm_pass: true});
    }

    await this.setState({[name]: "", _loginData: {...this.state._loginData, [name.toString().replace("_", "")]: value}});
    this.validateSignUp();
  };

  validateSignUp = () => {
    if (this.state._password_confirm_pass && this.state._loginData.password && this.state._terms_and_conditions_check) {
      this.setState({_disabled: false, _signupMessage: "", _signupStatus: ""});
    } else {
      this.setState({_disabled: true, _signupMessage: i18next.t("input all fields"), _signupStatus: "red"});
    }
  };

  loginClick = async (e: any) => {
    e.preventDefault();

    if (this.state._loginData.password === "") {
      this.setState({_loginMessage: i18next.t("Password is required")});
      return;
    } else {
      this.setState({_loginMessage: ""});
    }

    toggleSpinner();
    const res = await Request.post(`${appAuthUrl}/login-signup-confirm/${this.state._mailId}`, this.state._loginData);
    toggleSpinner();

    if (res.token) {
	    this.setState({_loginMessage: ""});
      // redirect the user after login to confirmation page.
      window.location.href = `${this.state._redirect}?token=${res.token}&expires=${res.expires}`;
    } else {
	    this.setState({_loginMessage: res.message});
    }
  };

  componentWillMount() {
    const params = new URLSearchParams(window.location.search);
    if(!params.get("mailId")) return this.props.history.push("/");
    this.setState({
      _mailId: params.get("mailId") || "",
      _email: params.get("confirmEmail") || "",
      _redirect: params.get("redirectUrl") || "",
      _loginData: {...this.state._loginData, email: params.get("confirmEmail") || ""}
    });
  }
}
